import { __decorate } from "tslib";
import { Vue, Component, Prop } from 'vue-property-decorator';
import VueQrCodeComponent from 'vue-qrcode-component';
import _ from 'lodash';
import { mainStore } from '@/store/';
let FranchisingPrePedidoPagamentoComponent = class FranchisingPrePedidoPagamentoComponent extends Vue {
    constructor() {
        super(...arguments);
        this.snackbar = false;
    }
    get storeUrl() {
        if (this.preInvoice.franchising_id) {
            switch (this.preInvoice.franchising_id) {
                case 1:
                    return 'https://loja.knnidiomas.com.br/login';
                case 2:
                    return 'https://loja.phenomidiomas.com.br/login';
            }
        }
        return `https://loja.${this.userProfile.escola_id === 1 ? 'knn' : 'phenom'}idiomas.com.br / login`;
    }
    get preInvoice() {
        return this.propPreInvoice;
    }
    get alunoName() {
        if (this.preInvoice) {
            const name = this.preInvoice.responsavel_full_name;
            if (name) {
                return _.first(name.split(' '));
            }
        }
    }
    get userProfile() {
        return mainStore.userProfile;
    }
    get linkAcessoLoja() {
        return `${this.storeUrl}?email=${this.preInvoice.responsavel_email}&password=${this.preInvoice.responsavel_cpf.slice(0, 4)}`;
    }
    async clipboardCopy(value) {
        this.snackbar = true;
        if (!!navigator.clipboard && window.isSecureContext) {
            await navigator.clipboard.writeText(value);
        }
        else {
            const textArea = document.createElement('textarea');
            textArea.value = value;
            textArea.style.position = 'fixed';
            document.body.appendChild(textArea);
            textArea.focus();
            textArea.select();
            await new Promise((res, rej) => {
                // here the magic happens
                document.execCommand('copy') ? res() : rej();
                textArea.remove();
            });
        }
    }
};
__decorate([
    Prop({ required: true, type: Object })
], FranchisingPrePedidoPagamentoComponent.prototype, "propPreInvoice", void 0);
FranchisingPrePedidoPagamentoComponent = __decorate([
    Component({
        components: { VueQrCodeComponent },
    })
], FranchisingPrePedidoPagamentoComponent);
export default FranchisingPrePedidoPagamentoComponent;
