import { __decorate } from "tslib";
import { Component, Vue } from 'vue-property-decorator';
import { startOfMonth, endOfMonth, format } from 'date-fns';
import { alunoStore } from '@/store';
import OpenNewTabComponent from '@/components/OpenNewTabComponent.vue';
import EntregarLivroButton from '@/components/EntregarLivroButton.vue';
import FranchisePreInvoiceVisualizaComponent from '@/components/FranchisePreInvoiceVisualizaComponent.vue';
let List = class List extends Vue {
    constructor() {
        super(...arguments);
        this.search = '';
        this.loading = false;
        this.dialogConfirmarDataEntregaLivro = false;
        this.dialogFranchisePreInvoiceVisualiza = false;
        this.contratoLivroId = 0;
        this.preInvoiceId = 0;
        this.items = [];
        this.nomeAlunoResponsavel = '';
        this.headers = [
            {
                text: 'Número',
                sortable: true,
                value: 'id',
                align: 'left',
            },
            {
                text: 'Criado em',
                sortable: true,
                value: 'created_at',
                align: 'left',
            },
            {
                text: 'Responsável pelo pedido - Nome',
                sortable: true,
                value: 'responsavel_nome',
                align: 'left',
            },
            {
                text: 'Responsável pelo pedido - CPF',
                sortable: true,
                value: 'responsavel_cpf',
                align: 'left',
            },
            {
                text: 'Livros - Alunos',
                sortable: true,
                value: 'items',
                align: 'left',
            },
            {
                text: 'Status do Pedido',
                sortable: true,
                value: 'status_id',
                align: 'left',
            },
            {
                text: 'Pedido atrelado ao aluno no Drive',
                sortable: true,
                value: 'contrato_livro_id',
                align: 'left',
            },
            {
                text: '',
                sortable: false,
                value: 'actions',
                align: 'left',
            },
        ];
    }
    entregarLivro(item) {
        this.contratoLivroId = item.contrato_livro_id;
        this.dialogConfirmarDataEntregaLivro = true;
    }
    async livroDiretoFranchising({ id }) {
        this.preInvoiceId = id;
        this.dialogFranchisePreInvoiceVisualiza = true;
    }
    onCancel() {
        this.preInvoiceId = 0;
        this.dialogConfirmarDataEntregaLivro = false;
    }
    async buscar() {
        await this.loadPage(this.nomeAlunoResponsavel);
    }
    async onConfirm() {
        await this.loadPage();
    }
    async loadPage(nomeAlunoResponsavel) {
        this.loading = true;
        let dataInicial = null;
        let dataFinal = null;
        if (!nomeAlunoResponsavel || nomeAlunoResponsavel.trim() === '') {
            const data = new Date();
            dataInicial = format(startOfMonth(data), 'yyyy-MM-dd');
            dataFinal = format(endOfMonth(data), 'yyyy-MM-dd');
        }
        this.items = await alunoStore.getLivrosAlunosMASTER({ nomeAlunoResponsavel, dataInicial, dataFinal });
        this.loading = false;
    }
    async mounted() {
        await this.loadPage();
    }
};
List = __decorate([
    Component({
        components: {
            OpenNewTabComponent,
            EntregarLivroButton,
            FranchisePreInvoiceVisualizaComponent,
        },
    })
], List);
export default List;
