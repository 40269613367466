import { __decorate } from "tslib";
import { Vue, Component, Prop } from 'vue-property-decorator';
import { contratoStore } from '@/utils/store-accessor';
import FranchisingPrePedidoPagamentoComponent from '@/components/FranchisingPrePedidoPagamentoComponent.vue';
let FranchisePreInvoiceVisualizaComponent = class FranchisePreInvoiceVisualizaComponent extends Vue {
    constructor() {
        super(...arguments);
        this.loading = false;
        this.preInvoiceData = null;
        this.preInvoiceHeaders = [
            { text: 'Livro', value: 'item_name' },
            { text: 'Quantidade', value: 'quantity' },
            { text: 'Aluno', value: 'nome_aluno' },
        ];
    }
    onCancel() {
        this.preInvoiceData = null;
        this.loading = false;
        this.$emit('on-cancel');
    }
    async handleDesvincularLivro() {
        this.$swal({
            title: 'Deseja realmente cancelar o pedido?',
            text: 'O pedido será cancelado na franchising e desvinculado do aluno!',
            icon: 'warning',
            showCancelButton: true,
            cancelButtonText: 'Voltar',
            confirmButtonText: 'Sim, eu confirmo!',
        }).then(async (result) => {
            if (result.isConfirmed) {
                await contratoStore.desvincularLivroMaster(this.propPreInvoiceId);
                this.$emit('on-confirm');
            }
        });
    }
    async mounted() {
        this.loading = true;
        this.preInvoiceData = await contratoStore.getMasterPreInvoice({
            preInvoiceId: this.propPreInvoiceId,
        });
        this.loading = false;
    }
};
__decorate([
    Prop({ type: Number })
], FranchisePreInvoiceVisualizaComponent.prototype, "propPreInvoiceId", void 0);
FranchisePreInvoiceVisualizaComponent = __decorate([
    Component({
        components: {
            FranchisingPrePedidoPagamentoComponent,
        },
    })
], FranchisePreInvoiceVisualizaComponent);
export default FranchisePreInvoiceVisualizaComponent;
