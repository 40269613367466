import { __decorate } from "tslib";
import { Vue, Component, Prop } from 'vue-property-decorator';
import VueQrCodeComponent from 'vue-qrcode-component';
import { ValidationObserver, ValidationProvider, Validator, } from 'vee-validate';
import { turmaStore } from '@/store';
import DateFieldComponent from '@/components/DateFieldComponent.vue';
Validator.extend('horario', {
    validate: async (value) => {
        const [hora, minutos] = value.split(':');
        if (!hora || parseInt(hora, 0) > 23 || !minutos || parseInt(minutos, 0) > 59) {
            return false;
        }
        return true;
    },
    getMessage: (field) => 'Hora inválida',
});
let ConfirmarEntregarLivroComponent = class ConfirmarEntregarLivroComponent extends Vue {
    constructor() {
        super(...arguments);
        // @ts-ignore
        this.entregaLivroData = this.dateAndTime(this.todayDate.toISOString())[0];
        // @ts-ignore
        this.entregaLivroHora = this.dateAndTime(this.todayDate.toISOString())[1];
        this.isSaving = false;
    }
    get todayDate() {
        const date = new Date();
        date.setHours(date.getHours() - 3);
        return date;
    }
    async onConfirmEntregarLivro() {
        this.isSaving = true;
        const dataDefault = new Date(`${this.entregaLivroData}T${this.entregaLivroHora}`);
        const timeZoneInMinutes = dataDefault.getTimezoneOffset();
        const timeZoneFormatado = String(timeZoneInMinutes / 60).padStart(2, '0');
        await turmaStore.setEntregaLivro({
            codigo: '0',
            contratoLivroId: this.propContratoLivroId,
            livroDataEntrega: `${this.entregaLivroData}T${this.entregaLivroHora}:00-${timeZoneFormatado}:00`,
            isEstorno: false,
        });
        this.isSaving = false;
        this.$emit('on-confirm');
    }
    onCancel() {
        this.$emit('on-cancel');
    }
};
__decorate([
    Prop({ required: true, type: Number })
], ConfirmarEntregarLivroComponent.prototype, "propContratoLivroId", void 0);
ConfirmarEntregarLivroComponent = __decorate([
    Component({
        components: {
            VueQrCodeComponent,
            ValidationObserver,
            ValidationProvider,
            DateFieldComponent,
        },
    })
], ConfirmarEntregarLivroComponent);
export default ConfirmarEntregarLivroComponent;
