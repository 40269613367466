import { __decorate } from "tslib";
import { Vue, Component, Prop } from 'vue-property-decorator';
import ConfirmarEntregarLivroComponent from '@/components/ConfirmarEntregarLivroComponent.vue';
import { contratoStore, turmaStore, userActionStore } from '@/store';
let EntregarLivroButton = class EntregarLivroButton extends Vue {
    constructor() {
        super(...arguments);
        this.dialogConfirmarDataEntregaLivro = false;
        this.contratoLivroId = 0;
        this.loading = false;
    }
    get userActionsEntregarLivroAllow() {
        return userActionStore.entregarLivroAllow;
    }
    async imprimirComprovanteEntregaLivro() {
        const html = await contratoStore.imprimirReciboEntregaMaterial(this.propItem.contrato_livro_id);
        const w = window.open();
        w.document.write(html);
        setTimeout(() => {
            w.print();
        }, 1000);
    }
    async estornarEntregaLivro() {
        this.$swal({
            title: 'Deseja realmente desfazer a entrega do livro?',
            text: '',
            icon: 'warning',
            showCancelButton: true,
            cancelButtonText: 'Voltar',
            confirmButtonText: 'Sim, eu confirmo!',
        }).then(async (result) => {
            if (result.isConfirmed) {
                const obj = {
                    codigo: '1',
                    contratoLivroId: this.propItem.contrato_livro_id,
                    livroDataEntrega: '1',
                    isEstorno: true,
                };
                this.loading = true;
                await turmaStore.setEntregaLivro(obj);
                this.onConfirm();
                this.loading = false;
            }
        });
    }
    async entregarLivro() {
        this.contratoLivroId = this.propItem.contrato_livro_id;
        this.dialogConfirmarDataEntregaLivro = true;
    }
    onCancel() {
        this.$emit('on-cancel');
    }
    onConfirm() {
        this.dialogConfirmarDataEntregaLivro = false;
        this.$emit('on-confirm');
    }
};
__decorate([
    Prop({ required: true, type: Object })
], EntregarLivroButton.prototype, "propItem", void 0);
EntregarLivroButton = __decorate([
    Component({
        components: {
            ConfirmarEntregarLivroComponent,
        },
    })
], EntregarLivroButton);
export default EntregarLivroButton;
